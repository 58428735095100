(function()
{
	/* VARIABLES */
	var enabledFilters = [];
	var wall = new freewall('#work-wall');


	$('.container, .container-fluid').each(function(i, elem)
	{
		$(this).css({
			'z-index': 50 - i
		});
	});



	$('#header_image').each(function()
	{
		$(this).height($(window).height() - ($('nav').outerHeight() * 1.5));

		$('#logo_overlay').css({
			'margin-top': ($('#header_image').height() / 2) - ($('#logo_overlay').height() / 2)
		});

		$('.mobile #logo_overlay').css({
			'margin-top': ($('#header_image').height() * 0.7) - ($('#logo_overlay').height() / 2)
		});
	});


	/* STICKY NAVIGATION */
	if($('#header_image').length)
	{
		var navPosY = $('nav').offset().top;
		var sbPosY = $('#social-bar').offset().top + $('#social-bar').height();
		var bodyPadding = parseInt($('body').css('padding-top').replace('px', ''));

		
		$(window).scroll(function()
		{
			if($(window).scrollTop() >= navPosY)
			{
				var addedPadding = $('nav').height() -5;

				$('header').addClass('sticky');
				$('body').css({'padding-top': addedPadding});
			} else {
				$('header').removeClass('sticky');
				$('body').css({'padding-top': bodyPadding});
			}
		});

		$(window).trigger('scroll');
	}




	setTimeout(function()
	{
		$('.for_sale, .for_rent').animate({
			'opacity': 1
		},300);
	}, 100);




	if($('.proposed-message').length)
	{
		$('[name="message"]').on('keyup', function()
		{
			$('.proposed-message').hide(300);
		});
	}



	/* GALLERY FUNCTIONALITIES */
	wall.reset({
	  	selector: '.wall-item',
	  	/*cellW: function(width) {
	      var cellWidth = width / 4;
	      return cellWidth - 20;
	    },*/
	    fixSize: {
	    	'block': '.wall-item',
	    	'width': 215,
	    	'height': 230
	    },
	    gutterY: 25,
	    animate: true,
	    onResize: function()
	    {
			wall.refresh();
		}
	});

	wall.fitWidth();
	//wall.fitZone();


	if($('#filters').length > 0 && getQueryVariable('f'))
	{
		activeFilters = getQueryVariable('f');

		activeFilters = activeFilters.split(',');

		$.each(activeFilters, function(i, elem)
		{
			$('#filters input[type="checkbox"][value="' + elem + '"]').prop('checked', true);
		});

		filterBySelected();
	}



	$('#filters input[type="checkbox"]').change(function(e)
	{
		filterBySelected();
	});



	function filterBySelected()
	{
		var filterArray = [];
		enabledFilters = [];

		$('#filters input[type="checkbox"]:checked').each(function(e)
		{
			enabledFilters.push($(this).val());
			filterArray.push('.wall-item[data-labels*="' + $(this).val() + '"]');
		});

		wall.filter(filterArray.join());

		if(enabledFilters.length === 0)
		{
			wall.unFilter();
		}
	}









	/* FANCYBOX */
	$('.project-item > a').fancybox({
		'transitionIn': 'elastic',
		'transitionOut': 'elastic',
		'overlayShow': true
	});



	function getQueryVariable(variable)
	{
	       var query = window.location.search.substring(1);
	       var vars = query.split("&");
	       for (var i=0;i<vars.length;i++) {
               	var pair = vars[i].split("=");
               	if(pair[0] == variable){
               		return pair[1];
               	}
	       }
	       return false;
	}



})();